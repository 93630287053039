body {background-color: lightgray;}
.avatar {border-radius: 100px;filter: grayscale(100%);}
.pull-left li {margin-right:4px;}
#fh5co-hero {height:160px;}
#answersTable {margin-left:20px;margin-top:35px;width:100%;float:left;position:relative;background-color:#fff;border-radius:5px;}
@media (min-width: 992px) {
    .col-answersTable {width: 78%;float:right;}
}
#answersTable tr {color:#484c50;}
#answersTable th {vertical-align:middle;}
#trysavescreen{color:#00abcf;text-decoration:none;display:flex;position:absolute;left:30%;margin-top:45px;cursor:pointer;}
#trysavescreen:hover{color:#5cb85c;text-decoration:underline;}
.modal-backdrop {z-index:-9999999;}
li.animate-box {margin-bottom:15px;margin-top:5px;}